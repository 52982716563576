import BasePlugin from '../BasePlugin'

export default class CountVisitorCommand extends BasePlugin {
  async execute (row, column, cell) {
    if (column.label === 'Открыть слушание') {
      let recordId = row.id
      this.context.$http.get(`${this.context.$config.api}/registryservice/registry/11/records/${recordId}/card`)
        .then((response) => {
          this.context.$parent.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CountVisitorCommand`,
            { 'publicDiscussionId': row.id },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          this.context.$nextTick(() => {
            this.context.$parent.openRegistryCard({
              registryId: 11,
              cardId: response.data[0].id,
              cardName: '',
              recordId: recordId
            })
          })
        })
    }
  }
}
